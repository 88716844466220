import { Controller } from "stimulus";
import { useMeta } from "stimulus-use";
import "@interactjs/auto-start";
import "@interactjs/actions/drag";
import "@interactjs/actions/drop";
import "@interactjs/actions/resize";
import "@interactjs/modifiers";
import "@interactjs/dev-tools";
import interact from "@interactjs/interact";
import Rails from "@rails/ujs";
import Sortable from "sortablejs";
import dragula from "dragula/dist/dragula";

export default class extends Controller {
  static targets = ["header"];
  static values = {
    date: String,
    category: String,
  };

  change_header(event) {
    console.log(event);
  }

  async header_key_press(event) {
    console.log("KEY PRESS");
    if (event.which === 13) {
      event.preventDefault();

      const date = this.dateValue;
      const old_category = event.target.dataset.tasksCategoryValue;
      const new_category = event.target.innerText;

      const url =
        "/users/1/tasks/update_category?date=" +
        date +
        "&old_category=" +
        old_category +
        "&new_category=" +
        new_category;

      // window.getSelection().removeAllRanges();
      const form = new RemoteForm({
        action: url,
        method: "post",
        target: "dunno:",
        params: "",
      });
      form.connect();
      await form.submit();
      form.disconnect();
    }
  }

  connect() {
    useMeta(this);
    this.category = this.element.querySelector("h5");
    this.position = { x: 0, y: 0 };

    this.csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    // ------------
    const left = "#tasks_Personal_tasks_container";
    const right = "#tasks_Personal_tasks_container";

    dragula([document.getElementById(left), document.getElementById(right)], {
      copy: function (el, source) {
        return source === document.getElementById(left);
      },
      accepts: function (el, target) {
        return target !== document.getElementById(left);
      },
    });

    console.log(dragula);

    // -------------

    console.log(
      `Connected to [TasksController] for ${this.category.textContent}`
    );

    const method = (e) => {
      const form = this.element.querySelector("form");
      const key = e.keyCode || e.which;
      if (key === 9) {
        // debugger; NO
        e.preventDefault();
        form.requestSubmit();
        //         const task_bodies = this.element.querySelectorAll(".task_body");
        // const last = task_bodies[task_bodies.length - 1];
      }
    };

    //debugger;

    setInterval(() => {
      const task_bodies = this.element.querySelectorAll(".task_body");
      task_bodies.forEach((taskBody, index) => {
        taskBody.removeEventListener("keydown", method, true);

        if (index === task_bodies.length - 1) {
          taskBody.addEventListener("keydown", method, true);
        }
      });
    }, 100);
    // debugger;

    // this.headerTarget.on("change", () => {
    //   alert(1);
    // });

    console.log(this.headerTarget);

    const position = { x: 0, y: 0 };

    const items = this.element.querySelectorAll("li");
    items.forEach((item) => {
      interact(item).draggable({
        manualStart: false,
        autoScroll: { container: this.element },
        onstart: (e) => {
          this.dragStart(e);
        },
        onmove: (e) => {
          this.dragMove(e);
        },
        onend: (e) => {
          this.dragEnd(e);
        },
      });
    });

    interact(this.element).dropzone({
      // only accept elements matching this CSS selector
      accept: ".drop_image",
      // Require a 75% element overlap for a drop to be possible
      overlap: 0.75,

      // listen for drop related events:

      ondropactivate: function (event) {
        // add active dropzone feedback
        // console.log("drop activate");
        event.target.classList.add("drop-active");
      },
      ondragenter: function (event) {
        console.log("drag enter");

        var draggableElement = event.relatedTarget;
        var dropzoneElement = event.target;

        // feedback the possibility of a drop
        dropzoneElement.classList.toggle("drop-target");
        draggableElement.classList.add("can-drop");
        //draggableElement.textContent = "Dragged in";
      },
      ondragleave: function (event) {
        // console.log("drag leave");

        // remove the drop feedback style
        event.target.classList.remove("drop-target");
        event.relatedTarget.classList.remove("can-drop");
        //event.relatedTarget.textContent = "Dragged out";
      },
      ondrop: async function (event) {
        const targetCategory = event.target.querySelector("h5").textContent;
        const sourceData = event.relatedTarget.dataset;

        console.log(
          "Move " +
            sourceData.taskIdValue +
            " from " +
            sourceData.taskCategoryValue +
            " to " +
            targetCategory
        );

        const csrf = document
          .querySelector("meta[name='csrf-token']")
          .getAttribute("content");

        const from_date =
          document.querySelector(".calendar_date").dataset.dateValue;
        const url =
          "/users/1/tasks/" +
          sourceData.taskIdValue +
          "/move_to_category?target_category=" +
          targetCategory +
          "&from_date=" +
          from_date;

        const form = new RemoteForm({
          action: url,
          method: "post",
          target: "dunno:",
          params: "",
        });

        console.log(form);
        form.connect();

        await form.submit();
        //  this.dispatch("success");
        form.disconnect();
      },
      ondropdeactivate: function (event) {
        // console.log("drop deactivate");

        // remove active dropzone feedback
        event.target.classList.remove("drop-active");
        event.target.classList.remove("drop-target");
      },
    });

    document.querySelectorAll(".day").forEach((day) => {
      interact(day).dropzone({
        // only accept elements matching this CSS selector
        accept: ".drop_image",
        // Require a 75% element overlap for a drop to be possible
        overlap: 0.1,

        // listen for drop related events:

        ondropactivate: function (event) {},
        ondragenter: function (event) {},
        ondragleave: function (event) {},
        ondrop: async function (event) {
          const targetDate =
            event.target.querySelector(".day-drop").dataset.dateValue;
          const taskId = event.relatedTarget.dataset.taskIdValue;
          const from_date =
            document.querySelector(".calendar_date").dataset.dateValue;

          const csrf = document
            .querySelector("meta[name='csrf-token']")
            .getAttribute("content");

          const url =
            "/users/1/tasks/" +
            taskId +
            "/move_to_date_drag_drop?target_date=" +
            targetDate +
            "&from_date=" +
            from_date;

          const form = new RemoteForm({
            action: url,
            method: "post",
            target: "dunno:",
            params: "",
          });

          console.log(form);
          form.connect();

          await form.submit();
          //  this.dispatch("success");
          form.disconnect();

          console.log("DROPPED INTO DAY");
        },
        ondropdeactivate: function (event) {},
      });
    });
  }

  dragStart(event) {
    if (this.dragging) {
      return false;
    }
    this.dragging = true;
    const base = event.target;
    this.setDragGhost(event, base);
  }

  setDragGhost(event, base) {
    const cursors = this.element.querySelector(".cursors");
    const interaction = event.interaction;
    const ghost = document.createElement("div");
    const text = base.querySelector(".task_name_link").innerText;
    ghost.className = "drop_image";
    ghost.innerHTML = text;
    Object.assign(ghost.dataset, base.dataset);
    cursors.append(ghost);
    const rect = base.getBoundingClientRect();
    this.position = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    interaction.start({ name: "drag" }, event.interactable, ghost);
  }

  removeDragGhost() {
    const cursors = this.element.querySelector(".cursors");
    cursors.innerHTML = "";
    // cursors.style.top = "0px";
    // cursors.style.left = "-5000px";
    // cursors.style.zIndex = 0;
  }

  dragEnd(event) {
    this.position = { x: 0, y: 0 };
    this.removeDragGhost();
    this.dragging = false;
  }

  dragMove(event) {
    const cursors = this.element.querySelector(".cursors");

    this.position.x += event.dx;
    this.position.y += event.dy;

    // // set position
    // cursors.style.top = this.position.x + "px";
    // cursors.style.left = this.position.y + "px";
    // cursors.style.zIndex = 999999;
    //
    // position.x += event.dx;
    // position.y += event.dy;

    cursors.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
  }
}

class RemoteForm {
  constructor(options) {
    this.options = options;
    this.options.params = this.options.params ?? {};

    this.element = document.createElement("form");
    this.element.action = this.action;
    this.element.method = this.method;
    this.element.target = this.target;
    this.element.style.display = "none";
  }

  // Actions

  connect() {
    document.body.appendChild(this.element);
  }

  disconnect() {
    this.element.remove();
  }

  submit() {
    const scrollTop = document.scrollingElement.scrollTop;

    this.reset();

    for (const [key, value] of Object.entries(this.params)) {
      this.append(key, value);
    }

    return new Promise((resolve) => {
      this.element.requestSubmit();

      if (this.turboDisabled) {
        return resolve();
      }

      this.element.addEventListener(
        "turbo:submit-end",
        () => {
          document.scrollingElement.scrollTo(0, scrollTop);
          resolve();
        },
        { once: true }
      );
    });
  }

  set(name, value) {
    this.options.params[name] = value;
    return this;
  }

  // Private

  get turboDisabled() {
    return this.element.closest("[data-turbo=false]") !== null;
  }

  get action() {
    return this.options.action;
  }

  get method() {
    return this.options.method === "get" ? "get" : "post";
  }

  get target() {
    return this.options.target;
  }

  get csrfParamName() {
    return getMeta("csrf-param");
  }

  get csrfToken() {
    return getMeta("csrf-token");
  }

  get railsParams() {
    if (this.options.method === "get") {
      return {};
    } else {
      return {
        _method: this.options.method,
        [this.csrfParamName]: this.csrfToken,
      };
    }
  }

  get params() {
    return Object.assign({}, this.railsParams, this.options.params);
  }

  get inputs() {
    return [...this.element.querySelectorAll("input[type=hidden]")];
  }

  reset() {
    this.inputs.forEach((input) => input.remove());
  }

  append(name, value) {
    const input = document.createElement("input");

    input.type = "hidden";
    input.name = name;
    input.value = value;

    this.element.appendChild(input);
  }
}

function getMeta(name) {
  const meta = document.querySelector(`meta[name=${name}]`);
  return meta && meta.content;
}

function reduceToParams(elements) {
  return elements.reduce(
    (hash, node) => Object.assign(hash, { [node.name]: node.value }),
    {}
  );
}

function stopEverything(e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
}
