import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { DirectUpload } from "@rails/activestorage";

const Uploader = () => {
  const input = document.querySelector("input[type=file]");

  const onDrop = useCallback((files) => {
    // Do something with the files
    Array.from(files).forEach((file) => uploadFile(file));
  }, []);

  const uploadFile = (file) => {
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    const url = "/rails/active_storage/direct_uploads"; // /attachments"; //input.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log(error);
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = "attachments[]"; // input.name;
        document.querySelector("#rx").appendChild(hiddenField);
        console.log(hiddenField);
      }
    });
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  document.querySelector("#btn_upload_attachments").style.display =
    acceptedFiles.length === 0 ? "none" : "block";

  return (
    <div
      style={{ position: "relative", zIndex: 6000, backgroundColor: "gray" }}
    >
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          multiple={"multiple"}
          data-direct-upload-url={"/rails/active_storage/direct_uploads"}
        />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      {files.length > 0 && (
        <div style={{ padding: 20 }}>
          <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
          </aside>
        </div>
      )}
    </div>
  );
};

Uploader.propTypes = {};
Uploader.defaultProps = {};

export default Uploader;
