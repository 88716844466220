import { Controller } from "stimulus";
import Sortable from "sortablejs";
import Rails from "@rails/ujs";
import dragula from "dragula/dist/dragula";

export default class extends Controller {
  connect() {
    const drake = dragula({
      copy: true,
      moves: function (el, container, handle) {
        return handle.classList.contains("handle");
      },
    });

    console.log("Connected to [Dragula]");

    document.querySelectorAll(".task_container").forEach((el) => {
      drake.containers.push(el);
    });

    document.querySelectorAll(".day_div").forEach((el) => {
      drake.containers.push(el);
    });

    drake.on("drop", (el, target, source, sibling) => {
      try {
        const task_id = el.querySelector(".task").dataset.taskIdValue;
        const date = target.dataset.date;

        const form = document.querySelector("#move_to_date_form");
        form.querySelector("#task_id").value = task_id;
        form.querySelector("#date").value = date;
        form.requestSubmit();
      } catch (e) {
        console.log("Failed drop");
      }
    });
    drake.on("drag", (el, target, source, sibling) => {
      // el.innerHTML = "[ TASK ]";
    });
  }
}
