import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { DirectUpload } from "@rails/activestorage";

const NuUploader = ({ prefix, parent_id }) => {
  const parent = document.querySelector(`#${parent_id}`);

  const onDrop = useCallback((files) => {
    Array.from(files).forEach((file) => uploadFile(file));
  }, []);

  const uploadFile = (file) => {
    const url = "/rails/active_storage/direct_uploads";
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        console.log(error);
      } else {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = `${prefix}[attachments][]`; // input.name;
        parent.appendChild(hiddenField);
        console.log(hiddenField);
      }
    });
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  //
  // document.querySelector("#btn_upload_attachments").style.display =
  //   acceptedFiles.length === 0 ? "none" : "block";

  return (
    <div
      style={{ position: "relative", zIndex: 6001, backgroundColor: "gray" }}
    >
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          multiple={"multiple"}
          data-direct-upload-url={"/rails/active_storage/direct_uploads"}
        />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      {files.length > 0 && (
        <div style={{ padding: 20 }}>
          <aside>
            <h4>Files</h4>
            <ul>{files}</ul>
          </aside>
        </div>
      )}
    </div>
  );
};

NuUploader.propTypes = {};
NuUploader.defaultProps = {};

export default NuUploader;
