import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  task_ids = [];

  connect() {
    console.log(`Connected to [TaskzController]`, this.element);
  }

  selectTask(event) {
    const element = event.currentTarget;
    const taskId = element.getAttribute("data-task_id");

    // Find the nearest ancestor with the class .task
    const taskElement = element.closest(".task");

    // Toggle class on the ancestor
    if (taskElement) {
      taskElement.classList.toggle("task-selected");
    }

    // Toggle class to visually show selection on the current element
    element.classList.toggle("task_selected");

    if (element.classList.contains("task_selected")) {
      this.addHiddenField(taskId);
    } else {
      this.removeHiddenField(taskId);
    }
  }

  addHiddenField(taskId) {
    const form = document.getElementById("bulk-form");
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "task_ids[]";
    input.value = taskId;
    form.appendChild(input);
  }

  removeHiddenField(taskId) {
    const form = document.getElementById("bulk-form");
    const hiddenInputs = form.querySelectorAll(
      `input[name="task_ids[]"][value="${taskId}"]`
    );
    hiddenInputs.forEach((input) => input.remove());
  }
}
