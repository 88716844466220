import Rails from "@rails/ujs";

import Autocomplete from "react-autocomplete";
import { Turbo, cable } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@fortawesome/fontawesome-free/css/all";
import dragula from "dragula/dist/dragula";

// import { Draggable } from "@shopify/draggable";

// --
// REACT
// --
const componentRequireContext = require.context("components", true);
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.useContext(componentRequireContext);

Rails.start();
ActiveStorage.start();

require("bootstrap");

import "controllers";

// const draggable = new Draggable.Draggable(document.querySelectorAll(".tasks"), {
//   draggable: ".task",
// });
//
// draggable.on("drag:start", () => console.log("drag:start"));
// draggable.on("drag:move", () => console.log("drag:move"));
// draggable.on("drag:stop", () => console.log("drag:stop"));
