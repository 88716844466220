import { Controller } from "stimulus";

const Keys = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  RIGHT: 39,
  LEFT: 37,
};

export default class extends Controller {
  static targets = ["input", "query", "results"];

  connect() {
    console.log("Connected to [SearchController]");

    this.form = this.element.querySelector("form");

    this.inputTarget.addEventListener("keyup", (e) => {
      this.form.querySelector("#query").value = this.inputTarget.value;
      this.form.requestSubmit();
    });
  }
}
