import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {
    console.log(`Connected to [ContextController]`, this.element);

    const modal = Modal.getOrCreateInstance(
      document.getElementById("taskModal")
    );
    modal.show();
  }

  openContext(event) {
    event.preventDefault();

    const modal = Modal.getOrCreateInstance(
      document.getElementById("taskModal")
    );
    modal.show();

    // Your custom logic for the openContext action
    console.log("openContext action triggered", modal);
  }
}
