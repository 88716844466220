import { Controller } from "stimulus";
import { post } from "@rails/request.js";
import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

// import { useMeta } from "stimulus-use";
// import "@interactjs/auto-start";
// import "@interactjs/actions/drag";
// import "@interactjs/actions/drop";
// import "@interactjs/actions/resize";
// import "@interactjs/modifiers";
// import "@interactjs/dev-tools";
// import interact from "@interactjs/interact";
import Sortable from "sortablejs";
import Rails from "@rails/ujs";

const Keys = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  RIGHT: 39,
  LEFT: 37,
  ADAM: "wtf",
};

export default class extends Controller {
  static targets = [
    "name",
    "nameHidden",
    "time",
    "timeHidden",
    "body",
    "bodyHidden",
    "mdp",
    "mdpHidden",
  ];

  connect() {
    // useMeta(this);
    const { slideToggle, slideUp, slideDown } = window.domSlider;
    console.log(`Connected to [TaskController]`);

    const allTargets = [
      this.nameTarget,
      this.timeTarget,
      this.bodyTarget,
      this.mdpTarget,
    ];

    console.log(allTargets);

    this.form = this.element.querySelector("form.task_form");
    const lastTaskId = document.querySelector("#last_created_task_id").value;
    const myId = this.element?.dataset?.taskIdValue;

    // console.log(lastTaskId + " vs " + myId);
    if (lastTaskId === myId || parseInt(myId) > parseInt(lastTaskId)) {
      this.nameTarget.focus();
      selectElementContents(this.nameTarget);
      document.querySelector("#last_created_task_id").value = "";
    }

    this.focused = [];

    // this.addTarget.addEventListener("click", (e) => {
    //   e.preventDefault();
    //   const data = { a: 1 };
    //   console.log("ADD TARGET");
    //   post("/users/1/tasks/" + myId + "/add_metric_data_point", {
    //     body: data,
    //     responseKind: "turbo-stream",
    //   });
    // });

    setInterval(() => {
      if (
        document.activeElement === this.nameTarget ||
        document.activeElement === this.timeTarget ||
        document.activeElement === this.bodyTarget ||
        document.activeElement === this.mdpTarget
      ) {
        slideDown({
          element: this.timeTarget,
          slideSpeed: 100,
        });

        slideDown({
          element: this.bodyTarget,
          slideSpeed: 100,
        });

        slideDown({
          element: this.mdpTarget,
          slideSpeed: 100,
        });
      } else {
        if (this.bodyTarget.innerText === "") {
          slideUp({
            element: this.bodyTarget,
            slideSpeed: 100,
          });
        }
        if (this.timeTarget.innerText === "") {
          slideUp({
            element: this.timeTarget,
            slideSpeed: 100,
          });
        }

        if (this.mdpTarget.innerText === "Add Metric") {
          slideUp({
            element: this.mdpTarget,
            slideSpeed: 100,
          });
        }
      }
    }, 100);

    [this.nameTarget, this.timeTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.ENTER) {
          e.preventDefault();
          this.form.requestSubmit();
          e.currentTarget.blur();
        }
      });
    });

    [this.nameTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.TAB || (key === Keys.RIGHT && isAtTextEnd())) {
          e.preventDefault();
          this.timeTarget.focus();
          selectElementContents(this.timeTarget);
        }
      });
    });

    [this.nameTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.BACKSPACE && e.currentTarget.innerText === "") {
          // select previous
          e.preventDefault();
          const previous = e.currentTarget
            .closest("turbo-frame")
            .previousElementSibling.querySelector(".task_name");

          setEndOfContenteditable(previous);
          previous.focus();

          e.currentTarget.parentElement.parentElement.parentElement.parentElement
            .querySelector(".delete_form")
            .requestSubmit();
        }
      });
    });

    [this.nameTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.UP) {
          const pos = getHTMLCaretPosition(e.currentTarget);
          const previous = e.currentTarget
            .closest("turbo-frame")
            ?.previousElementSibling?.querySelector(".task_body");

          const prevCategory =
            e.currentTarget.closest(".tasks").parentElement
              .previousElementSibling;

          console.log(pos);
          if (pos === 0 && previous) {
            e.preventDefault();

            setEndOfContenteditable(previous);
            previous.focus();
          } else if (pos === 0 && prevCategory) {
            prevCategory.querySelector(".task_name").focus();
          }
        }
      });
    });

    [this.nameTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.DOWN) {
          e.preventDefault();
          const body = e.currentTarget
            .closest("turbo-frame")
            .querySelector(".task_body");

          body.focus();
        }
      });
    });

    [this.bodyTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.UP) {
          const pos = getHTMLCaretPosition(e.currentTarget);
          if (pos === 0) {
            e.preventDefault();
            const name = e.currentTarget
              .closest("turbo-frame")
              .querySelector(".task_name");

            setEndOfContenteditable(name);
            name.focus();
          }
        }
      });
    });

    [this.bodyTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.DOWN) {
          const pos = getHTMLCaretPosition(e.currentTarget);
          if (isAtTextEnd) {
            const nextTaskName = e.currentTarget
              .closest("turbo-frame")
              ?.nextElementSibling?.querySelector(".task_name");
            const nextCategory =
              e.currentTarget.closest(".tasks").parentElement
                .nextElementSibling;

            e.preventDefault();

            if (nextTaskName) {
              setEndOfContenteditable(nextTaskName);
              nextTaskName.focus();
            } else if (nextCategory) {
              nextCategory.querySelector(".task_name").focus();
            }
          }
        }
      });
    });

    [this.timeTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        if (key === Keys.TAB || key === Keys.DOWN) {
          e.preventDefault();
          this.bodyTarget.focus();
          selectElementContents(this.bodyTarget);
        }
      });
    });

    [this.timeTarget].forEach((target) => {
      target.addEventListener("keydown", (e) => {
        var key = e.keyCode || e.which;
        const pos = getHTMLCaretPosition(e.currentTarget);

        if (pos === 0 && key === Keys.LEFT) {
          e.preventDefault();
          this.nameTarget.focus();
          setEndOfContenteditable(this.nameTarget);
        }
      });
    });

    allTargets.forEach((target) => {
      target.addEventListener("blur", (e) => {
        this.nameHiddenTarget.value = this.nameTarget.innerText;
        this.bodyHiddenTarget.value = this.bodyTarget.innerText;
        this.timeHiddenTarget.value = this.timeTarget.innerText;

        this.form.requestSubmit();
      });
    });
    // Sortable.create(document.querySelector("#tasks_Personal_tasks_container"), {
    //   group: "foo",
    //   animation: 100,
    //   handle: ".handle"
    // });
  }
}

function selectElementContents(el) {
  var range = document.createRange();
  range.selectNodeContents(el);
  var sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

function setEndOfContenteditable(contentEditableElement) {
  var range, selection;
  if (document.createRange) {
    //Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); //get the selection object (allows you to change selection)
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range); //make the range you have just created the visible selection
  } else if (document.selection) {
    //IE 8 and lower
    range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    range.select(); //Select the range (make it the visible selection
  }
}

//
// ================================================================
//

// class RemoteForm {
//   constructor(options) {
//     this.options = options;
//     this.options.params = this.options.params ?? {};
//
//     this.element = document.createElement("form");
//     this.element.action = this.action;
//     this.element.method = this.method;
//     this.element.target = this.target;
//     this.element.style.display = "none";
//   }
//
//   // Actions
//
//   connect() {
//     document.body.appendChild(this.element);
//   }
//
//   disconnect() {
//     this.element.remove();
//   }
//
//   submit() {
//     const scrollTop = document.scrollingElement.scrollTop;
//
//     this.reset();
//
//     for (const [key, value] of Object.entries(this.params)) {
//       this.append(key, value);
//     }
//
//     return new Promise((resolve) => {
//       this.element.requestSubmit();
//
//       if (this.turboDisabled) {
//         return resolve();
//       }
//
//       this.element.addEventListener(
//         "turbo:submit-end",
//         () => {
//           document.scrollingElement.scrollTo(0, scrollTop);
//           resolve();
//         },
//         { once: true }
//       );
//     });
//   }
//
//   set(name, value) {
//     this.options.params[name] = value;
//     return this;
//   }
//
//   // Private
//
//   get turboDisabled() {
//     return this.element.closest("[data-turbo=false]") !== null;
//   }
//
//   get action() {
//     return this.options.action;
//   }
//
//   get method() {
//     return this.options.method === "get" ? "get" : "post";
//   }
//
//   get target() {
//     return this.options.target;
//   }
//
//   get csrfParamName() {
//     return getMeta("csrf-param");
//   }
//
//   get csrfToken() {
//     return getMeta("csrf-token");
//   }
//
//   get railsParams() {
//     if (this.options.method === "get") {
//       return {};
//     } else {
//       return {
//         _method: this.options.method,
//         [this.csrfParamName]: this.csrfToken,
//       };
//     }
//   }
//
//   get params() {
//     return Object.assign({}, this.railsParams, this.options.params);
//   }
//
//   get inputs() {
//     return [...this.element.querySelectorAll("input[type=hidden]")];
//   }
//
//   reset() {
//     this.inputs.forEach((input) => input.remove());
//   }
//
//   append(name, value) {
//     const input = document.createElement("input");
//
//     input.type = "hidden";
//     input.name = name;
//     input.value = value;
//
//     this.element.appendChild(input);
//   }
// }
//
// function getMeta(name) {
//   const meta = document.querySelector(`meta[name=${name}]`);
//   return meta && meta.content;
// }
//
// function reduceToParams(elements) {
//   return elements.reduce(
//     (hash, node) => Object.assign(hash, { [node.name]: node.value }),
//     {}
//   );
// }
//
// function stopEverything(e) {
//   e.preventDefault();
//   e.stopPropagation();
//   e.stopImmediatePropagation();
// }

function getCaretPosition(node) {
  var range = window.getSelection().getRangeAt(0),
    preCaretRange = range.cloneRange(),
    caretPosition,
    tmp = document.createElement("div");

  preCaretRange.selectNodeContents(node);
  preCaretRange.setEnd(range.endContainer, range.endOffset);
  tmp.appendChild(preCaretRange.cloneContents());
  caretPosition = tmp.innerHTML.length;
  return caretPosition;
}

function getHTMLCaretPosition(element) {
  var textPosition = getCaretPosition(element),
    htmlContent = element.innerHTML,
    textIndex = 0,
    htmlIndex = 0,
    insideHtml = false,
    htmlBeginChars = ["&", "<"],
    htmlEndChars = [";", ">"];

  if (textPosition == 0) {
    return 0;
  }

  while (textIndex < textPosition) {
    htmlIndex++;

    // check if next character is html and if it is, iterate with htmlIndex to the next non-html character
    while (htmlBeginChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
      // console.log('encountered HTML');
      // now iterate to the ending char
      insideHtml = true;

      while (insideHtml) {
        if (htmlEndChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
          if (htmlContent.charAt(htmlIndex) == ";") {
            htmlIndex--; // entity is char itself
          }
          // console.log('encountered end of HTML');
          insideHtml = false;
        }
        htmlIndex++;
      }
    }
    textIndex++;
  }

  //console.log(htmlIndex);
  //console.log(textPosition);
  // in htmlIndex is caret position inside html
  return htmlIndex;
}

function isAtTextEnd() {
  var sel = window.getSelection(),
    offset = sel.focusOffset;
  sel.modify("move", "forward", "character");
  if (offset == sel.focusOffset) return true;
  else {
    sel.modify("move", "backward", "character");
    return false;
  }
}
