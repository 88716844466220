import { Controller } from "stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["value", "notes", "button"];

  static values = {
    open: String,
    metricId: Number,
  };

  connect() {
    console.log("Connected to [CanvasController]");
    const c = new bootstrap.Offcanvas(this.element.closest(".offcanvas"));
    console.log(this.openValue);
    if (this.openValue !== "false") {
      c.show();
    }

    this.valueTarget.addEventListener("keydown", (e) => {
      if (e.key === "ArrowDown") {
        // Only prevent default if actually handling the event
        e.preventDefault();
        this.notesTarget.focus();
      }
    });

    // Event listener for ArrowLeft at the beginning of the text field
    this.valueTarget.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft" && e.target.selectionStart === 0) {
        e.preventDefault();

        // Select the .metric_data_point with the specific hidden metric_id value
        const selector = `.metric_data_point input[type='hidden'][id='metric_id'][value='${this.metricIdValue}']`;
        const hiddenInput = document.querySelector(selector);

        if (hiddenInput) {
          // Navigate to the closest .metric_data_point parent and find the target input
          const parentMetricDataPoint =
            hiddenInput.closest(".metric_data_point");
          if (parentMetricDataPoint) {
            const targetInput = parentMetricDataPoint.querySelector(
              'input[type="text"][id="metric_data_point_string_value"]'
            );
            if (targetInput) {
              targetInput.focus();
            }
          }
        }
      }
    });

    this.buttonTarget.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") {
        e.preventDefault();

        // Select the .metric_data_point with the specific hidden metric_id value
        const selector = `.metric_data_point input[type='hidden'][id='metric_id'][value='${this.metricIdValue}']`;
        const hiddenInput = document.querySelector(selector);

        if (hiddenInput) {
          // Navigate to the closest .metric_data_point parent and find the target input
          const parentMetricDataPoint =
            hiddenInput.closest(".metric_data_point");
          if (parentMetricDataPoint) {
            const targetInput = parentMetricDataPoint.querySelector(
              'input[type="text"][id="metric_data_point_string_value"]'
            );
            if (targetInput) {
              targetInput.focus();
            }
          }
        }
      }
    });

    this.buttonTarget.addEventListener("keydown", (e) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        this.notesTarget.focus();
      }
    });

    this.notesTarget.addEventListener("keydown", (e) => {
      if (e.key === "ArrowUp" && this.notesTarget.selectionStart === 0) {
        // Focus on the valueTarget if cursor is at the beginning and ArrowUp is pressed
        e.preventDefault();
        this.valueTarget.focus();
      } else if (
        e.key === "ArrowDown" &&
        this.notesTarget.selectionStart === this.notesTarget.value.length
      ) {
        // Focus on the buttonTarget if cursor is at the end and ArrowDown is pressed
        e.preventDefault();
        this.buttonTarget.focus();
      }
    });
  }
}
