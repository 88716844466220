import { Controller } from "stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  // static values = {
  //   open: String,
  // };
  connect() {
    console.log("Connected to [MirrorController]");

    this.element.onclick = () => {
      this.toggle();
    };
  }

  toggle() {
    this.element.style.display =
      this.element.style.display === "none" ? "block" : "none";
  }
}
