// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

// https://stackoverflow.com/questions/46003422/how-to-render-react-component-into-itself-in-a-recursive-way

const Task = (task) => {
  const { id, name, tasks = [], onSubmit, onTab, onBack } = task;

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    } else if (event.key === "Tab") {
      event.preventDefault();
      onTab(task);
    } else if (event.key === "Backspace") {
      event.preventDefault();
      event.stopPropagation();
      onBack();
    }
  };

  const onChange = () => {};
  const _subs = [];
  tasks.forEach((subtask, i) => {
    _subs.push(
      <div style={{ marginLeft: 25 }}>
        <Task
          object={subtask}
          id={subtask.id}
          name={subtask.name}
          key={"task_" + i}
          onSubmit={() => {
            alert("sub add");
          }}
          tasks={[]}
          parent={task}
          onTab={() => {}}
          onBack={() => onBack(subtask, task)}
        />
      </div>
    );
  });

  return (
    <div>
      <input type="checkbox" />
      <input
        className="todo"
        autoFocus
        type="text"
        onKeyDown={handleKeyDown}
        value={name + "(" + id + ")"}
        onChange={onChange}
      />
      <span>
        {id} / {tasks?.length || 0}
      </span>

      {_subs}
    </div>
  );
};

const TodoEditor = (props) => {
  const [count, setCount] = useState(1);

  const [data, setData] = useState([
    { id: 1, name: "Something here #1" },
    { id: 2, name: "Something here #2" },
    {
      id: 3,
      name: "Something here #3",
      tasks: [
        {
          id: "3a",
          name: "Subtask #1",
        },
        {
          id: "4a",
          name: "Subtask #2",
        },
      ],
    },
    { id: 5, name: "Something here #5" },
  ]);

  const addNewTask = () => {
    const newTask = { id: data.length + 1, name: "" };
    setData([...data, newTask]);
  };

  const items = [];

  const onBack = (child, parent, self) => {
    if (parent) {
      //const myIndex = parent.tasks.findIndex((t) => t.id === child.id);
      // remove me from parent tasks
      // let _newList = parent.tasks.filter((item) => item !== child);

      const _data = data;

      //console.log(_data[2]);

      //_data[2].tasks = [];
      //console.log(_data[2]);

      const pIndex = _data.findIndex((t) => t.id === parent.id);
      const p = _data.find((t) => t.id === parent.id);
      const c = p.tasks.find((t) => t.id === child.id);
      // alert(p);
      p.tasks = p.tasks.filter((t) => t.id !== child.id);

      _data.splice(pIndex + 1, 0, c);

      //parent.tasks = [];

      //_data.updated = new Date();
      // parent.tasks = _newList;
      // alert(_data);
      setData([..._data]);
    }
  };

  const onTab = (task) => {
    if (!task.parent) {
      // let _tasks = data;
      // const self = task;
      // const parent = self.parent;
      //
      // alert(self.id);
      // alert(parent.id);

      // alert(task);

      console.log(data);
      console.log("----");
      console.log(task.object);

      const myIndex = data.findIndex((t) => t.id === task.id);

      const child = data[myIndex];
      const parent = data[myIndex - 1];

      if (parent) {
        const _newList = data.filter((item) => item !== child);

        if (!parent.tasks) {
          parent.tasks = [];
          console.log("CREATED PARENT TASK ARRAY");
        } else {
          console.log("PARENT ALREADY HAD CHILDREN");
          console.log(parent.tasks);
        }
        parent.tasks.push(child);

        setData(_newList);
      }

      //alert(prevIndex);

      // const _newList = _tasks.filter((item) => item !== child);
      //
      // parent.tasks = [child];
      //
      // setData(_newList);
    }
  };

  for (let i = 0; i < data.length; i++) {
    const task = data[i];

    const subtasks = data[i]?.tasks;

    items.push(
      <Task
        id={task.id}
        name={task.name}
        key={"task_" + i}
        tasks={subtasks}
        onSubmit={addNewTask}
        onTab={onTab}
        onBack={onBack}
      />
    );
  }

  return (
    <>
      <div
        onClick={() => {
          let _tasks = data;
          const child = data[1];
          const parent = data[0];

          const _newList = _tasks.filter((item) => item !== child);

          // if (!parent.tasks) {
          //   parent.tasks = [];
          // }
          parent.tasks = [child];

          setData(_newList);
        }}
      >
        REARRANGE SUBTASK
      </div>

      <div>{items}</div>
    </>
  );
};

TodoEditor.defaultProps = {
  name: "David",
};

TodoEditor.propTypes = {
  name: PropTypes.string,
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Hello name="React" />,
    document.body.appendChild(document.createElement("div"))
  );
});

export default TodoEditor;
