// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const Hello = (props) => {
  const [count, setCount] = useState(1);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setCount(count + 1);
    }
  };

  const items = [];

  for (let i = 0; i < count; i++) {
    items.push(
      <div>
        <input type="checkbox" />
        <input
          className="todo"
          autoFocus
          type="text"
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  }

  return (
    <>
      <div
        onClick={() => {
          // alert("ADAM 123");
        }}
      >
        Hello {props.name} ADAM xxx thats faster!!!
        <div>
          <div>{count}</div>
          {items}
        </div>
      </div>
    </>
  );
};

Hello.defaultProps = {
  name: "David",
};

Hello.propTypes = {
  name: PropTypes.string,
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Hello name="React" />,
    document.body.appendChild(document.createElement("div"))
  );
});

export default Hello;
