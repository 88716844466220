import { Controller } from "stimulus";
import * as bootstrap from "bootstrap";
import * as Turbo from "@hotwired/turbo";

const Keys = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  RIGHT: 39,
  LEFT: 37,
};

export default class extends Controller {
  static targets = ["value", "focus"];
  static values = {
    metricid: Number,
    date: String,
    touched: String,
  };

  connect() {
    this.keysPressed = {};

    // this.valueTarget.focus();
    const len = this.valueTarget.value.length;
    if (this.touchedValue !== "") {
      this.valueTarget.focus();
      this.valueTarget.setSelectionRange(len, len);
    }

    this.valueTarget.addEventListener("focus", (e) => {
      console.log(
        "load canvas for " + this.metricidValue + " and " + this.dateValue
      );

      e.currentTarget.closest("tr").querySelector("a").click();
      // Turbo.visit("/users/1/calendar/5/10/2021/metrics/6", {
      //   action: "replace",
      // });
    });

    this.form = this.element.querySelector("form");
    this.valueTarget.addEventListener("keydown", (e) => {
      this.keysPressed[e.key] = true;
      const myself = e.target.closest(".metric_data_point");

      const metricDataPoints = document
        .getElementById("daily_metrics")
        .querySelectorAll(".metric_data_point");

      if (e.key == "ArrowUp") {
        const prevElement = findSiblingElement(
          metricDataPoints,
          myself,
          "previous"
        );
        prevElement.querySelector("#metric_data_point_string_value").focus();
      }

      if (e.key == "ArrowDown") {
        const prevElement = findSiblingElement(
          metricDataPoints,
          myself,
          "next"
        );
        prevElement.querySelector("#metric_data_point_string_value").focus();
      }

      // zip over
      if (
        e.key === "ArrowRight" &&
        e.target.selectionStart == e.target.value.length
      ) {
        document.getElementById("metric_data_point_string_value").focus();
        e.preventDefault();
      }

      // Show/hide overlay
      if (this.keysPressed["Shift"] && e.key === "Enter") {
        e.preventDefault();
        console.log("OVERLAY");

        const canvas = document.querySelector("#offcanvasRight");
        canvas.classList.toggle("show");

        canvas.addEventListener("hidden.bs.offcanvas", () => {
          this.valueTarget.focus();
        });
      }
    });

    this.valueTarget.addEventListener("keyup", (e) => {
      delete this.keysPressed[e.key];
    });

    if (this.focusTarget && this.focusTarget.value) {
      const element = this.valueTarget;
      const strLength = element.value.length * 2;
      element.focus();
      element.setSelectionRange(strLength, strLength);
    }

    $("[data-mdp-target='value']").each((index, element) => {
      element.tabIndex = parseInt(index + 1);
    });
  }
}

function findSiblingElement(nodeList, currentElement, direction) {
  // Convert NodeList to Array for easier index manipulation
  let elementsArray = Array.from(nodeList);
  let currentIndex = elementsArray.indexOf(currentElement);

  console.log(elementsArray);
  console.log(currentIndex);

  if (direction === "next") {
    // Return next element or null if current is the last
    return currentIndex >= 0 && currentIndex < elementsArray.length - 1
      ? elementsArray[currentIndex + 1]
      : null;
  } else if (direction === "previous") {
    // Return previous element or null if current is the first
    return currentIndex > 0 ? elementsArray[currentIndex - 1] : null;
  } else {
    // Invalid direction
    return null;
  }
}
